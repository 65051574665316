import Vue from 'vue'
// include vue-custom-element plugin to Vue
import VueCustomElement from 'vue-custom-element'
import sanitize from './lib/sanitize'; // eslint-disable-line no-unused-vars
import VueSanitize from 'vue-sanitize';
import VueFab from './lib/vue-fab/vue-fab.js'
import vuetify from './plugins/vuetify.js'
// import wrap from "@vue/web-component-wrapper";
Vue.use(VueCustomElement);
Vue.use(VueSanitize);
Vue.use(VueFab);
import Main from './App.vue';

Main.vuetify = vuetify
// const wrappedElement = wrap(Vue, Main);
// window.customElements.define('voffice-widget', wrappedElement)
Vue.customElement('voffice-widget', Main);

// import Vue from 'vue'
// // include vue-custom-element plugin to Vue
// import VueCustomElement from 'vue-custom-element'
// import VueFab from './lib/vue-fab/vue-fab.js'
// import vuetify from './plugins/vuetify.js'
// // import wrap from "@vue/web-component-wrapper";
// Vue.use(VueCustomElement);
// Vue.use(VueFab);
// import Main from './App.vue';

// Main.vuetify = vuetify
// Vue.customElement('voffice-widget', Main)
// // const wrappedElement = wrap(Vue, Main);
// // window.customElements.define('voffice-widget', Main)
// // Vue.customElement('voffice-widget', Main, {
// //   shadow: true,
// //   props: [
// //     "namespace",
// //     "title",
// //     "color",
// //     "icon"
// //   ],
// //   beforeCreateVueInstance(root) {
// //     const rootNode = root.el.getRootNode();
    
// //     root.shadowRoot = rootNode;
// //     return root;
// //   },
// // });