<template>
  <div class="voffice-headerPanel" :style="`border-color: ${color}`">
    <img :src="headerIcon"/>
    <p class="voffice-pTitle" :style="`color: ${color}`">
      {{title}}
    </p>
    <div class="voffice-closeTT" :class="{active: showTT}">
      {{getTranslatedCloseText}}
    </div>
    <button @mouseenter="showTT = true" @mouseleave="showTT=false" :style="{background: '#c3c3c3'}" class="voffice-closeButton" @click="closeBeacon()">
      <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" color="#4f4f4f" class="svg-inline--fa fa-times fa-w-10">
        <path fill="#4f4f4f"
        d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"
        class="">
        </path>
      </svg>
    </button>
  </div>
</template>
<script>
export default {
  props: ["color", "title", "headerIcon", "language"],
  components: { },
  data () {
    return {
      showTT: false,
    }
  },
  methods: {
    closeBeacon(){
      const actionButton = document.querySelector('.voffice-actionButton')
      if ( actionButton ){
        actionButton.click();
      }
    },
  },
  computed: {
    getTranslatedCloseText(){
      let language = this.language || navigator.language;
      language = language.split('-')[0].toLowerCase();
      switch(language){
        case 'es':
          return 'Cerrar el botón de vídeo';
        case 'en':
          return 'Close Video-Button';
        case 'de':
        default:
          return 'Video-Button schliessen';
      }
    },
  }
}
</script>
<style scoped lang="scss">
@import url(https://fonts.googleapis.com/css?family=Muli:300,400,500,700);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
.voffice-pTitle{
  font-size: 20px;
}
.voffice-headerPanel{
    text-align: center;
    color: #2a3133;
    padding: 5px;
    margin: 0px 10px;
    border-bottom: 2px solid rgb(195, 195, 195);;
    display: flex;
    align-content: center;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    /* width: calc(100% - 30px); */
    font-size: 16px;
    background-color: #f5f5f5;
    font-family: Roboto, sans-serif;
    border-top-right-radius: 4px !important;
    border-top-left-radius: 4px !important;
}
.voffice-headerPanel img{
  height: 30px;
  width: 30px;
  margin-right: 5px;
  margin-top: 0px !important;
}
.voffice-headerPanel p{
  margin: 0px 10px 0px 10px;
  vertical-align: middle;
  text-align: left;
}
.voffice-closeButton{
  // top: 10px;
  position: absolute;
  border: none;
  width: 35px;
  border-radius: 5px;
  cursor: pointer !important;
  height: 30px;
  text-align: center;
  // padding: 7px;
  right: 8px;
  & svg{
    height: 20px;
  }
}
.voffice-closeTT{
    background: rgba(97,97,97,.9);
    color: #fff;
    border-radius: 4px;
    font-size: 14px;
    line-height: 22px;
    display: inline-block;
    padding: 5px 16px;
    position: absolute;
    text-transform: none;
    width: auto;
    opacity: 0;
    pointer-events: none;
    right: 0px;
    top: -30px;
    &.active{
      opacity: 0.9;
    }
}
</style>
