<template>
   <v-expansion-panels accordion v-model="panel">
      <v-expansion-panel v-for="team, index in teams" :key="index">
        <v-expansion-panel-header class="text-left voffice-headerPanels" expand-icon="mdi-menu-down">
         {{team.team}} ({{team.teamMembers.length}})
         <span class="voffice-chevDown" :style="{right: panel == index ? 5 : ''}">

           <svg version="1.0" xmlns="http://www.w3.org/2000/svg" v-if="panel !== index"
              width="24.000000pt" height="24.000000pt" viewBox="0 0 24.000000 24.000000"
              preserveAspectRatio="xMidYMid meet">

              <g transform="translate(0.000000,24.000000) scale(0.100000,-0.100000)"
              :fill="color" stroke="none">
              <path d="M30 130 c0 -13 71 -60 90 -60 19 0 90 47 90 60 0 17 -30 11 -61 -11
              l-29 -20 -29 20 c-31 22 -61 28 -61 11z"/>
              </g>
            </svg>

            <svg version="1.0" xmlns="http://www.w3.org/2000/svg" v-if="panel == index"
              width="30.000000pt" height="30.000000pt" viewBox="0 0 30.000000 30.000000"
              preserveAspectRatio="xMidYMid meet">

              <g transform="translate(0.000000,30.000000) scale(0.100000,-0.100000)"
              :fill="color" stroke="none">
              <path d="M90 174 c-30 -26 -56 -55 -58 -65 -5 -27 4 -24 60 21 28 22 55 40 60
              40 5 0 31 -18 57 -40 53 -45 61 -48 61 -21 0 14 -107 112 -122 111 -1 0 -28
              -21 -58 -46z"/>
              </g>
            </svg>
         </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="text-left">
          <Users v-for="contact in team.teamMembers"
            :namespace="namespace"
            :key="contact.uuid"
            :user="contact"
            :beaconSettings="beaconSettings"
            :search="search"
            v-show="getShowUser(contact)"/>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
</template>


<script>
import Users from "./Users.vue";
export default {
  components: { Users },
  props: ['teams', 'namespace', 'color', 'beaconSettings'],
  data () {
    return {
      ItemIndex: null,
      panel: null
    }
  },
  methods: {
    getShowUser(contact){
      if ( contact.user.connected && !contact.user.isInCall){
        return true;
      }else if ( contact.user.connected && contact.user.isInCall && contact.user.allowBeaconWhileInCall == 'active'){
        return true;
      }else if ( contact.user.connected && contact.user.isInCall && contact.user.allowBeaconWhileInCall !== 'active' && this.beaconSettings.beaconInactiveUsers !== 'hidden'){
        return true;
      }else if ( !contact.user.connected && this.beaconSettings.beaconInactiveUsers !== 'hidden' ){
        return true;
      }
      return false;
    },
    selectIndex(Index) {
      this.ItemIndex = Index;
    },
    handlerUserClick(){
      window.open(`${this.namespace}/api/beaconCallInvite/#${this.user.uuid}`,  "_blank")
    },
    showPanel(team){
      const currentTeam = this.teams.find(e => e.team === team);
      // console.log('CurrentTean', currentTeam, this.search)
      // console.log('search', this.search);
      // console.log('condition', currentTeam.teamMembers.find(e => e.user.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1))
      if ( this.search == ''){
        return true;
      }else if ( currentTeam.teamMembers.find(e => e.user.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1 )){
        return true;
      }else{
        return false;
      }
    }
  },
  computed: {
    // drawUser(){
    //   if ( this.search == ''){
    //     return true;
    //   }else if ( this.user.user.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1){
    //     return true;
    //   }else{
    //     return false;
    //   }
    // },
    // getUserTeam(){
    //   if ( this.user.user.teams && this.user.user.teams.length > 0 ){
    //     return this.user.user.teams.join(', ')
    //   }else{
    //     return false;
    //   }
    // }
  }
}
</script>
<style scoped lang="scss">
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);

.voffice-headerPanels{
  font-size: 14px !important;
  background: none !important;
  border: none !important;
  text-align: left !important;
  height: 38px !important;
  width: 100%  !important;
  font-family: Roboto,sans-serif !important;;
  position: relative !important;;
  padding: 13px !important;;
  cursor: pointer !important;;
  font-weight: normal !important;
  color: black !important;
}
.voffice-chevDown{
  position: absolute;
  right: 10px;
  top: 9px;
  svg{
    height: 20px;
  }
}
.voffice-userDiv{
  width: calc(100% - 25px);
  /*padding: 10px;
  align-content: center;
  align-items: center;
  font-family: Roboto,sans-serif;
  text-align: left;
  display: flex;
  font-size: 14px;
  position: relative;*/
}
.voffice-userDiv h4{
  margin: 0px;
  font-family: Roboto, sans-serif;
}
/*.voffice-userImg{
  height: 35px;
  width: 35px;
  vertical-align: middle;
  margin-right: 5px;
  border-radius: 5px;
}*/
/*.voffice-callButton{
  float: right;
  top: 4px;
  position: relative;
  border: none;
  background: green;
  /* padding: 6px;
  width: 35px;
  border-radius: 5px;
  cursor: pointer;
  height: 30px;
  text-align: center;
  padding: 7px;
  line-height: normal;
  font-size: unset;

}
*/
.voffice-callButton svg{
  height: 15px;
  /* position: relative;
  top: 2px; */
}
.voffice-hidden{
  display: none;
}
.voffice-userTeam{
  margin-top: 2px;
  color: gray;
  min-width: 0;
  overflow: hidden;
  flex: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}
/*.voffice-userInfo{
  margin: 0px;
  min-width: 0;
  overflow: hidden;
  flex: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}
.voffice-userTT{
  position: absolute;
  background: #3f3e3ed6;
  padding: 6px;
  border-radius: 5px;
  color: white;
  top: -23px;
  left: 45px;
  font-weight: normal;
  font-family: 'ROBOTO';
  font-size: 12px;
  opacity: 0;
  max-width: 150px; 
  line-height: 1.6;
}
*/
.voffice-userTT.active{
  opacity: 1;
}
</style>
