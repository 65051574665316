<template>
  <div class="voffice-Footer">
    <div class="voffice-colum">
      <!-- Footer -->
      <img :src="footerIcon" class="voffice-footerLogo"/>
      <!-- <span class="voffice-footerTitle"> {{getTranslatedText}} </span> -->
      <span class="voffice-footerTitle" v-html="$sanitize(checkForUrls(getNoUsersMessage, false))"></span>
    </div>
  </div>
</template>
<script>
export default {
  components: { },
  props: ['beaconLogo', 'footerIcon', 'language', 'beaconSettings'],
  data () {
    return {}
  },
  methods: {
    checkForUrls(message, newline = true) {
      if (!message) return;
      const linkExpBlacklist =
          /["'>](((https?|ftp|file):\/\/)?[-A-Z0-9+&@'#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])(["']|<\/a>)/gim,
        linkExp =
          /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@'#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi,
        wwwExp = /(^|[^/]\b)(www\.[^\s<]+(\b|$))/gim,
        hyperlinkBlacklist = [];
      // Search hyperlinks with <a> tag
      message.replace(linkExpBlacklist, (...args) => {
        if (hyperlinkBlacklist.indexOf(args[1]) === -1)
          hyperlinkBlacklist.push(args[1]);
      });
      // Replace hyperlinks ignoring hyperlink blacklist
      return message
        .replace(linkExp, (...args) => {
          if (hyperlinkBlacklist.indexOf(args[1]) === -1) {
            return `${
              newline ? "<br>" : ""
            }<a style="text-decoration: none;" target="_blank" rel="noopener noreferrer nofollow" href="${
              args[1]
            }">${args[1]}</a>${newline ? "<br>" : ""}`;
          }
          return args[0];
        })
        .replace(wwwExp, (...args) => {
          if (hyperlinkBlacklist.indexOf(args[2]) === -1) {
            return `${args[1]}${
              newline ? "<br>" : ""
            }<a style="text-decoration: none;" target="_blank" rel="noopener noreferrer nofollow" href="http://${
              args[2]
            }">${args[2]}</a>${newline ? "<br>" : ""}`;
          }
          return args[0];
        });
    },
  },
  computed:{
    getNoUsersMessage() {
      let language = this.language || navigator.language;
      language = language.split("-")[0].toLowerCase();
      if (!this.getCustomNoUsersMessage) {
        switch (language) {
          case "es":
            return "Actualmente no hay empleados en línea. Por favor, inténtelo más tarde.";
          case "en":
            return "There are currently no employees online. Please try again later.";
          case "de":
          default:
            return "Derzeit sind keine Mitarbeiter online. Bitte versuchen Sie es später erneut.";
        }
      } else {
        return this.getCustomNoUsersMessage;
      }
    },
    getCustomNoUsersMessage() {
      let language = this.language || navigator.language;
      language = language.split("-")[0].toLowerCase();
      switch (language) {
        case "es":
          return this.beaconSettings?.noUserMessageES || this.beaconSettings?.noUserMessage || '';
        case "en":
          return this.beaconSettings?.noUserMessageEN || this.beaconSettings?.noUserMessage || '';
        case "de":
        default:
          return this.beaconSettings?.noUserMessage || '';
      }
    },
    getTranslatedText(){
      let language = this.language || navigator.language;
      language = language.split('-')[0].toLowerCase();
      switch(language){
        case 'es':
          return 'vOffice Videoconferencia';
        case 'en':
          return 'vOffice videoconferencing';
        case 'de':
        default:
          return 'vOffice Videokonferenzen';
      }
    }
  }
}
</script>
<style scoped lang="scss">
@import url(https://fonts.googleapis.com/css?family=Muli:300,400,500,700);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
.voffice-Footer{
  width: 365px !important;
  min-width: 365px;
  max-width: 365px;
  height: 55px;
  background-color: #2a3133;
  border-top: 1px solid #f2f2f2;
  bottom: 0;
  position: absolute;
  border-radius: 0px 0px 4px 4px;
  display: flex;
  // padding: 0px 10px;
  font-family: Roboto, sans-serif;
}
.voffice-colum{
  display: flex;
  width: 100%;
  height: 55px;
  font-family: Roboto, sans-serif;
  align-items: center;
  margin-left: 10px;
  /* justify-content: center; */
  .voffice-footerLogo{
    height: 35px;
    margin-right: 10px;
  }
  .voffice-footerTitle{
    font-family: Roboto, sans-serif;
    color: white !important;
    font-size: 11px !important;
    font-weight: 300 !important;
    text-align: left;
    line-height: normal;
    margin-right: 15px;
  }
}
</style>
