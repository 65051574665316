const sanitize = require('sanitize-html');

/**
 * Customize sanitize-html options
 */
sanitize.defaults = {
  ...sanitize.defaults,
  allowedTags: sanitize.defaults.allowedTags.concat(['u', 's', 'h1', 'h2']),
  allowedAttributes: {
    a: sanitize.defaults.allowedAttributes.a.concat(['rel'])
  },
  // allowedSchemes: [...sanitize.defaults.allowedSchemes, 'voffice'],
  transformTags: {
    a: sanitize.simpleTransform('a', { rel: 'noopener noreferrer nofollow', target: '_blank' }, true)
  }
};

export default sanitize;
